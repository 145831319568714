var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tSample",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tSampleForm",
          attrs: {
            model: _vm.tSampleForm,
            rules: _vm.tSampleFormRule,
            "label-width": 90,
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 300,
                            expression: "300",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 100,
                          placeholder: "请输入名称",
                        },
                        model: {
                          value: _vm.tSampleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.tSampleForm, "name", $$v)
                          },
                          expression: "tSampleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "条码份数", prop: "barcodeNum" } },
                    [
                      _c("InputNumber", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: false,
                          min: 0,
                          placeholder: "请输入条码份数",
                        },
                        model: {
                          value: _vm.tSampleForm.barcodeNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.tSampleForm, "barcodeNum", $$v)
                          },
                          expression: "tSampleForm.barcodeNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "规格", prop: "spec" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 300,
                            expression: "300",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          placeholder: "请输入规格",
                        },
                        model: {
                          value: _vm.tSampleForm.spec,
                          callback: function ($$v) {
                            _vm.$set(_vm.tSampleForm, "spec", $$v)
                          },
                          expression: "tSampleForm.spec",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "是否采血" } },
                    [
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.tSampleForm.needTakeBlood,
                            callback: function ($$v) {
                              _vm.$set(_vm.tSampleForm, "needTakeBlood", $$v)
                            },
                            expression: "tSampleForm.needTakeBlood",
                          },
                        },
                        [
                          _c(
                            "Radio",
                            { attrs: { label: "1", disabled: _vm.disabled } },
                            [_c("span", [_vm._v("是")])]
                          ),
                          _c(
                            "Radio",
                            { attrs: { label: "0", disabled: _vm.disabled } },
                            [_c("span", [_vm._v("否")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "标本代码", prop: "code" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 300,
                            expression: "300",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          placeholder: "请输入标本代码",
                        },
                        model: {
                          value: _vm.tSampleForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.tSampleForm, "code", $$v)
                          },
                          expression: "tSampleForm.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "排序", prop: "orderNum" } },
                    [
                      _c("InputNumber", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 300,
                            expression: "300",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          editable: false,
                          placeholder: "请输入排序",
                        },
                        model: {
                          value: _vm.tSampleForm.orderNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.tSampleForm, "orderNum", $$v)
                          },
                          expression: "tSampleForm.orderNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "是否打印" } },
                    [
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.tSampleForm.isPrint,
                            callback: function ($$v) {
                              _vm.$set(_vm.tSampleForm, "isPrint", $$v)
                            },
                            expression: "tSampleForm.isPrint",
                          },
                        },
                        [
                          _c(
                            "Radio",
                            { attrs: { label: "1", disabled: _vm.disabled } },
                            [_c("span", [_vm._v("是")])]
                          ),
                          _c(
                            "Radio",
                            { attrs: { label: "0", disabled: _vm.disabled } },
                            [_c("span", [_vm._v("否")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "关联码" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 300,
                            expression: "300",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          placeholder: "请输入关联码",
                        },
                        model: {
                          value: _vm.tSampleForm.liscode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tSampleForm, "liscode", $$v)
                          },
                          expression: "tSampleForm.liscode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "容量" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 300,
                            expression: "300",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          placeholder: "请输入容量",
                        },
                        model: {
                          value: _vm.tSampleForm.capacity,
                          callback: function ($$v) {
                            _vm.$set(_vm.tSampleForm, "capacity", $$v)
                          },
                          expression: "tSampleForm.capacity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: _vm.disabled ? "primary" : "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }